function hideAgeGate(){
    $('#ageGate').css({ 'display' : 'none' });
}

function fadeAgeGate(){
    $('#ageGate').animate({
        opacity : 0
    }, 300);
    setTimeout(function(){
        hideAgeGate();
    }, 301);
}

function hideMain(){
    $('main#main').fade();
}

function showAgeGate() {
    $('#ageGate .age-gate-content-wrapper').animate({ opacity : 1 }, 300);
}

function showMain() {
    $('main#main').animate({ opacity : 1}, 300);
}

$(document).ready(function(){
    if( !document.cookie || !document.cookie.includes('wordpress_over_21') ) {
        showAgeGate();
    } else {
        hideAgeGate();
        showMain();
    }
})
